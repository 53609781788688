import { useAiParamsExtractionSubscription } from './useAiParamsExtractionSubscription';
import { useAttachmentModal } from './useAttachmentModal';
import { useAttachPatientFile } from './useAttachPatientFile';
import { useCancelFile } from './useCancelFile';
import { useDicomReportModalControls } from './useDicomReportModalControls';
import { useEditPrefilledValue } from './useEditPrefilledValue';
import { useGenerateAndSavePdfPatientFile } from './useGenerateAndSavePdfPatientFile';
import { useMultidisciplinaryReportModalControls } from './useMultidisciplinaryReportModalControls';
import { usePrefilledValueControls } from './usePrefilledValueControls';
import { usePrescriptionModalControls } from './usePrescriptionModalControls';
import { useReportModalControls } from './useReportModalControls';
import { useRestoreFile } from './useRestoreFile';
import { useSubmitPdfData } from './useSubmitPdfData';
import { useVerifyIdentity } from './useVerifyIdentity';
import { useVerifyIdentityNoveltyModalControls } from './useVerifyIdentityNoveltyModalControls';

export { useAttachmentsStore } from './useAttachmentsStore';

export { useDocumentsStore } from './useDocumentsStore';

export { useMultipleReportModalControls } from './useMultipleReportModalControls';

export type { IAttachmentsStoreActions, IAttachmentsStore } from './useAttachmentsStore';

export const attachmentsModel = {
  useVerifyIdentity,
  useVerifyIdentityNoveltyModalControls,
  useMultidisciplinaryReportModalControls,
  useReportModalControls,
  useDicomReportModalControls,
  usePrescriptionModalControls,
  usePrefilledValueControls,
  useSubmitPdfData,
  useEditPrefilledValue,
  useAttachmentModal,
  useAttachPatientFile,
  useGenerateAndSavePdfPatientFile,
  useCancelFile,
  useRestoreFile,
  useAiParamsExtractionSubscription,
};
