import { FormikHelpers } from 'formik';
import { useContext } from 'react';

import { AttachmentFileModalContext } from '../context';

import { IUseSubmitPdfDataReturn } from './useSubmitPdfData';

import { MedicalReportFormValues } from 'features/MedicalReports';
import { IVerifyIdentityModalProps } from 'features/Users/components/VerifyIdentityModal/types';

interface IUseVerifyIdentityParams<T extends MedicalReportFormValues = MedicalReportFormValues> {
  setIsOtpStep: (isOtpStep: boolean) => void;
  setIsSubmittingOtp: (isSubmittingOtp: boolean) => void;
  isSubmittingOtp: boolean;
  onSubmit: IUseSubmitPdfDataReturn<T>['onSubmit'];
  values: T;
  resetForm: FormikHelpers<T>['resetForm'];
  onLegacyOtpSubmitSuccess?: VoidFunction;
}

export const useVerifyIdentity = <T extends MedicalReportFormValues = MedicalReportFormValues>({
  setIsOtpStep,
  setIsSubmittingOtp,
  isSubmittingOtp,
  onSubmit,
  values,
  resetForm,
  onLegacyOtpSubmitSuccess,
}: IUseVerifyIdentityParams<T>): Omit<IVerifyIdentityModalProps, 'onClose'> => {
  const { setHasFinishedDocumentCreation } = useContext(AttachmentFileModalContext);

  const onSubmitLegacyOtp = (otp: string): void => {
    setIsSubmittingOtp(true);

    onSubmit(values, { resetForm }, otp, (): void => {
      onLegacyOtpSubmitSuccess?.();
      setHasFinishedDocumentCreation(true);
    });
  };

  const onSubmitPushNotificationOtp = (otp: string): void => {
    setIsSubmittingOtp(true);

    onSubmit(values, { resetForm }, otp, () => {
      setHasFinishedDocumentCreation(true);
    });
  };

  return {
    onCancel: (): void => {
      setIsOtpStep(false);
    },
    otpModalProps: {
      onSubmitOtp: onSubmitLegacyOtp,
      isActionWithOtpLoading: isSubmittingOtp,
    },
    onSubmitPushNotificationOtp,
  };
};
