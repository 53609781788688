import { Yup } from '@lib/react-components';
import { useFormik } from 'formik';
import { useState } from 'react';

import { PrefilledPDF } from '../context';

import { usePrefilledValueControls } from './usePrefilledValueControls';
import { useSubmitPdfData } from './useSubmitPdfData';
import { useVerifyIdentity } from './useVerifyIdentity';

import { Maybe, PDFDataStorageType } from '__generated__/types';
import { IMedicalReportRecipient, MedicalReportFormValues, MedicalReportModalProps } from 'features/MedicalReports';
import { IVerifyIdentityModalProps } from 'features/Users/components/VerifyIdentityModal/types';
import { getFormErrors } from 'lib/hooks/formErrorsHook';

interface IUseAttachmentsModalParams<FormValues extends MedicalReportFormValues = MedicalReportFormValues> {
  attachmentType: PDFDataStorageType;
  onLegacyOtpSubmitSuccess?: VoidFunction;
  doctor: IMedicalReportRecipient;
  handleSubjects: (values: FormValues) => string[];
  validationSchema: Yup.AnyObjectSchema;
  getInitialValues: (prefilledPdf: Maybe<PrefilledPDF>, doctor: IMedicalReportRecipient) => FormValues;
  setModalOpened: (modalOpened: boolean) => void;
}

export interface IUseAttachmentsModalReturn<ModalProps extends MedicalReportModalProps = MedicalReportModalProps> {
  isOtpStep: boolean;
  modalProps: Omit<ModalProps, 'patient' | 'doctor'>;
  verifyIdentityProps: IVerifyIdentityModalProps;
}

export const useAttachmentModal = <
  ModalProps extends MedicalReportModalProps = MedicalReportModalProps,
  FormValues extends MedicalReportFormValues = MedicalReportFormValues,
>({
  attachmentType,
  validationSchema,
  getInitialValues,
  doctor,
  onLegacyOtpSubmitSuccess,
  handleSubjects,
  setModalOpened,
}: IUseAttachmentsModalParams<FormValues>): IUseAttachmentsModalReturn<ModalProps> => {
  const [isOtpStep, setIsOtpStep] = useState<boolean>(false);
  const [isSubmittingOtp, setIsSubmittingOtp] = useState<boolean>(false);
  const [prefilledValue] = usePrefilledValueControls();

  const [isPrescriptionInvalid, setIsPrescriptionInvalid] = useState(false);

  const { onSubmit, onSubmitStep, onCloseModal } = useSubmitPdfData<FormValues>({
    handleSubjects,
    setIsOtpStep,
    fileType: attachmentType,
    isAttachingNewFileToRequest: false,
    setIsSubmittingOtp,
    setModalOpened,
    isInvalidated: isPrescriptionInvalid,
  });

  const formikProps = useFormik<FormValues>({
    initialValues: getInitialValues(prefilledValue, doctor),
    enableReinitialize: true,
    onSubmit: onSubmitStep,
    validateOnMount: true,
    validationSchema,
  });

  const { errors, touched, values, resetForm } = formikProps;
  const getError = getFormErrors(errors, touched);

  const verifyIdentityProps = useVerifyIdentity({
    isSubmittingOtp,
    onLegacyOtpSubmitSuccess,
    onSubmit,
    resetForm,
    setIsOtpStep,
    setIsSubmittingOtp,
    values,
  });

  // @ts-expect-error todo Fix typings
  const modalProps: ModalProps = {
    getError,
    prefilledValue,
    onClose: onCloseModal,
    formikProps,
    isPrescriptionInvalid,
    setIsPrescriptionInvalid,
  };

  return {
    isOtpStep,
    modalProps,
    verifyIdentityProps: { ...verifyIdentityProps, onClose: onCloseModal },
  };
};
